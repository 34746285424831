/**
 * Данные для вкладки "Сервис сменных ковров"
 * changesPerWeek - количество замен в неделю,
 * rate1change - цена одной замены,
 * rate4weeks - цена замен за 4 недели
 * rate1_1 - deprecated.
 *
 * Например: колво замен в неделю 3, цена одной замены 84, цена за 4 недели: 84 * 3 * 4 = 1008
 */

export const CHANGABLE_MATRIX = [{
    width: 60,
    height: 85,
    price: 4400,
    cleaning: 290,
    rates: [
        {changesPerWeek: 1, rate1change: 150.00, rate4weeks: 600.00, rate1_1: 98.00},
        {changesPerWeek: 2, rate1change: 135.00, rate4weeks: 1080.00, rate1_1: 88.00},
        {changesPerWeek: 3, rate1change: 130.00, rate4weeks: 1560.00, rate1_1: 84.00},
        {changesPerWeek: 4, rate1change: 130.00, rate4weeks: 2080.00, rate1_1: 84.00},
        {changesPerWeek: 5, rate1change: 130.00, rate4weeks: 2600.00, rate1_1: 84.00},
        {changesPerWeek: 6, rate1change: 130.00, rate4weeks: 3120.00, rate1_1: 84.00},
        {changesPerWeek: 7, rate1change: 130.00, rate4weeks: 3640.00, rate1_1: 84.00},
        {changesPerWeek: 0.5, rate1change: 195.00, rate4weeks: 390.00, rate1_1: 128.00}
    ]
}, {
    width: 85,
    height: 150,
    price: 11000,
    cleaning: 575,
    rates: [
        {changesPerWeek: 1, rate1change: 300.00, rate4weeks: 1200.00, rate1_1: 196.00},
        {changesPerWeek: 2, rate1change: 270.00, rate4weeks: 2160.00, rate1_1: 177.00},
        {changesPerWeek: 3, rate1change: 255.00, rate4weeks: 3060.00, rate1_1: 167.00},
        {changesPerWeek: 4, rate1change: 255.00, rate4weeks: 4080.00, rate1_1: 167.00},
        {changesPerWeek: 5, rate1change: 255.00, rate4weeks: 5100.00, rate1_1: 167.00},
        {changesPerWeek: 6, rate1change: 255.00, rate4weeks: 6120.00, rate1_1: 167.00},
        {changesPerWeek: 7, rate1change: 255.00, rate4weeks: 7140.00, rate1_1: 167.00},
        {changesPerWeek: 0.5, rate1change: 390.00, rate4weeks: 780.00, rate1_1: 255.00}
    ]
}, {
    width: 115,
    height: 180,
    price: 17600,
    cleaning: 725,
    rates: [
        {changesPerWeek: 1, rate1change: 420.00, rate4weeks: 1680.00, rate1_1: 275.00},
        {changesPerWeek: 2, rate1change: 380.00, rate4weeks: 3040.00, rate1_1: 248.00},
        {changesPerWeek: 3, rate1change: 360.00, rate4weeks: 4320.00, rate1_1: 234.00},
        {changesPerWeek: 4, rate1change: 360.00, rate4weeks: 5760.00, rate1_1: 234.00},
        {changesPerWeek: 5, rate1change: 360.00, rate4weeks: 7200.00, rate1_1: 234.00},
        {changesPerWeek: 6, rate1change: 360.00, rate4weeks: 8640.00, rate1_1: 234.00},
        {changesPerWeek: 7, rate1change: 360.00, rate4weeks: 10080.00, rate1_1: 234.00},
        {changesPerWeek: 0.5, rate1change: 545.00, rate4weeks: 1090.00, rate1_1: 358.00}
    ]
}, {
    width: 85,
    height: 300,
    price: 21700,
    cleaning: 805,
    rates: [
        {changesPerWeek: 1, rate1change: 520.00, rate4weeks: 2080.00, rate1_1: 341.00},
        {changesPerWeek: 2, rate1change: 470.00, rate4weeks: 3760.00, rate1_1: 307.00},
        {changesPerWeek: 3, rate1change: 445.00, rate4weeks: 5340.00, rate1_1: 290.00},
        {changesPerWeek: 4, rate1change: 445.00, rate4weeks: 7120.00, rate1_1: 290.00},
        {changesPerWeek: 5, rate1change: 445.00, rate4weeks: 8900.00, rate1_1: 290.00},
        {changesPerWeek: 6, rate1change: 445.00, rate4weeks: 10680.00, rate1_1: 290.00},
        {changesPerWeek: 7, rate1change: 445.00, rate4weeks: 12460.00, rate1_1: 290.00},
        {changesPerWeek: 0.5, rate1change: 680.00, rate4weeks: 1360.00, rate1_1: 444.00}
    ]
}, {
    width: 115,
    height: 240,
    price: 23500,
    cleaning: 865,
    rates: [
        {changesPerWeek: 1, rate1change: 550.00, rate4weeks: 2200.00, rate1_1: 363.00},
        {changesPerWeek: 2, rate1change: 500.00, rate4weeks: 4000.00, rate1_1: 327.00},
        {changesPerWeek: 3, rate1change: 470.00, rate4weeks: 5640.00, rate1_1: 309.00},
        {changesPerWeek: 4, rate1change: 470.00, rate4weeks: 7520.00, rate1_1: 309.00},
        {changesPerWeek: 5, rate1change: 470.00, rate4weeks: 9400.00, rate1_1: 309.00},
        {changesPerWeek: 6, rate1change: 470.00, rate4weeks: 11280.00, rate1_1: 309.00},
        {changesPerWeek: 7, rate1change: 470.00, rate4weeks: 13160.00, rate1_1: 309.00},
        {changesPerWeek: 0.5, rate1change: 720.00, rate4weeks: 1440.00, rate1_1: 472.00}
    ]
}, {
    width: 150,
    height: 250,
    price: 32000,
    cleaning: 970,
    rates: [
        {changesPerWeek: 1, rate1change: 755.00, rate4weeks: 3020.00, rate1_1: 495.00},
        {changesPerWeek: 2, rate1change: 680.00, rate4weeks: 5440.00, rate1_1: 446.00},
        {changesPerWeek: 3, rate1change: 645.00, rate4weeks: 7740.00, rate1_1: 422.00},
        {changesPerWeek: 4, rate1change: 645.00, rate4weeks: 10320.00, rate1_1: 422.00},
        {changesPerWeek: 5, rate1change: 645.00, rate4weeks: 12900.00, rate1_1: 422.00},
        {changesPerWeek: 6, rate1change: 645.00, rate4weeks: 15480.00, rate1_1: 422.00},
        {changesPerWeek: 7, rate1change: 645.00, rate4weeks: 18060.00, rate1_1: 422.00},
        {changesPerWeek: 0.5, rate1change: 980.00, rate4weeks: 1960.00, rate1_1: 644.00}
    ]
}, {
    width: 115,
    height: 400,
    price: 39000,
    cleaning: 1520,
    rates: [
        {changesPerWeek: 1, rate1change: 920.00, rate4weeks: 3680.00, rate1_1: 605.00},
        {changesPerWeek: 2, rate1change: 830.00, rate4weeks: 6640.00, rate1_1: 545.00},
        {changesPerWeek: 3, rate1change: 780.00, rate4weeks: 9360.00, rate1_1: 515.00},
        {changesPerWeek: 4, rate1change: 780.00, rate4weeks: 12480.00, rate1_1: 515.00},
        {changesPerWeek: 5, rate1change: 780.00, rate4weeks: 15600.00, rate1_1: 515.00},
        {changesPerWeek: 6, rate1change: 780.00, rate4weeks: 18720.00, rate1_1: 515.00},
        {changesPerWeek: 7, rate1change: 780.00, rate4weeks: 21840.00, rate1_1: 515.00},
        {changesPerWeek: 0.5, rate1change: 1195.00, rate4weeks: 2390.00, rate1_1: 787.00}
    ]
}, {
    width: 150,
    height: 600,
    price: 76500,
    cleaning: 2250,
    rates: [
        {changesPerWeek: 1, rate1change: 1805.00, rate4weeks: 7220.00, rate1_1: 1188.00},
        {changesPerWeek: 2, rate1change: 1630.00, rate4weeks: 13040.00, rate1_1: 1070.00},
        {changesPerWeek: 3, rate1change: 1535.00, rate4weeks: 18420.00, rate1_1: 1010.00},
        {changesPerWeek: 4, rate1change: 1535.00, rate4weeks: 24560.00, rate1_1: 1010.00},
        {changesPerWeek: 5, rate1change: 1535.00, rate4weeks: 30700.00, rate1_1: 1010.00},
        {changesPerWeek: 6, rate1change: 1535.00, rate4weeks: 36840.00, rate1_1: 1010.00},
        {changesPerWeek: 7, rate1change: 1535.00, rate4weeks: 42980.00, rate1_1: 1010.00},
        {changesPerWeek: 0.5, rate1change: 2345.00, rate4weeks: 4690.00, rate1_1: 1545.00}
    ]
}, {
    width: 150,
    height: 300,
    price: 38300,
    cleaning: 1440,
    rates: [
        {changesPerWeek: 1,	rate1change: 905.00,	rate4weeks: 3620.00,	rate1_1: 505.00},
        {changesPerWeek: 2,	rate1change: 820.00,	rate4weeks: 6560.00,	rate1_1: 455.00},
        {changesPerWeek: 3,	rate1change: 770.00,	rate4weeks: 9240.00,	rate1_1: 430.00},
        {changesPerWeek: 4,	rate1change: 770.00,	rate4weeks: 12320.00,	rate1_1: 430.00},
        {changesPerWeek: 5,	rate1change: 770.00,	rate4weeks: 15400.00,	rate1_1: 430.00},
        {changesPerWeek: 6,	rate1change: 770.00,	rate4weeks: 18480.00,	rate1_1: 430.00},
        {changesPerWeek: 7,	rate1change: 770.00,	rate4weeks: 21560.00,	rate1_1: 430.00},
        {changesPerWeek: 0.5, rate1change: 1175.00,	rate4weeks: 2350.00,	rate1_1: 657.00}
    ]
}, {
    width: 150,
    height: 400,
    price: 5100,
    cleaning: 1750,
    rates: [
        {changesPerWeek: 1,	rate1change: 1210.00,	rate4weeks: 4840.00,	rate1_1: 505.00},
        {changesPerWeek: 2,	rate1change: 1095.00,	rate4weeks: 8760.00,	rate1_1: 455.00},
        {changesPerWeek: 3,	rate1change: 1025.00,	rate4weeks: 12300.00,	rate1_1: 430.00},
        {changesPerWeek: 4,	rate1change: 1025.00,	rate4weeks: 16400.00,	rate1_1: 430.00},
        {changesPerWeek: 5,	rate1change: 1025.00,	rate4weeks: 20500.00,	rate1_1: 430.00},
        {changesPerWeek: 6,	rate1change: 1025.00,	rate4weeks: 24600.00,	rate1_1: 430.00},
        {changesPerWeek: 7,	rate1change: 1025.00,	rate4weeks: 28700.00,	rate1_1: 430.00},
        {changesPerWeek: 0.5,	rate1change: 1565.00,	rate4weeks: 3130.00,	rate1_1: 657.00}
    ]
}, {
    width: 115,
    height: 200,
    price: 19600,
    cleaning: 725,
    rates: [
        {changesPerWeek: 1, rate1change: 420.00, rate4weeks: 1680.00, rate1_1: 275.00},
        {changesPerWeek: 2, rate1change: 380.00, rate4weeks: 3040.00, rate1_1: 248.00},
        {changesPerWeek: 3, rate1change: 360.00, rate4weeks: 4320.00, rate1_1: 234.00},
        {changesPerWeek: 4, rate1change: 360.00, rate4weeks: 5760.00, rate1_1: 234.00},
        {changesPerWeek: 5, rate1change: 360.00, rate4weeks: 7200.00, rate1_1: 234.00},
        {changesPerWeek: 6, rate1change: 360.00, rate4weeks: 8640.00, rate1_1: 234.00},
        {changesPerWeek: 7, rate1change: 360.00, rate4weeks: 10080.00, rate1_1: 234.00},
        {changesPerWeek: 0.5, rate1change: 545.00, rate4weeks: 1090.00, rate1_1: 358.00}
    ]
}


].sort(function(a,b){return a.width * a.height > b.width * b.height ? 1 : -1;});

export const INTERNAL_CHANGABLE_MATRIX = CHANGABLE_MATRIX
/**
 * Цены для вкладки "Модульные покрытия"
 */
export const PRICES = {
    ARABESKA: {
        PRICE: 3900 / 50, // 50 pieces in sq.meter
        WIDTH: 140,
        HEIGHT: 140
    },
    CLEANSTEP: {
        PRICE: 3600 / 25, // 25 pieces in sq.meter
        WIDTH: 400,
        HEIGHT: 100,
    },
    BORDER: 500, // Кант
    FASTENERS: 20
};

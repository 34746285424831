<template>
    <select :value="size" class="select" @change="e => $emit('change', e.target.value)">
        <option
            v-for="(item, key) in changableMatrix"
            :key="key"
            :value="`${item.width}x${item.height}`"
        >{{ item.width }} x {{ item.height }}
        </option>
    </select>
</template>

<script>
import {CHANGABLE_MATRIX, INTERNAL_CHANGABLE_MATRIX} from "@/constants/prices";

    export default {
        name: "SizeSelector",
        props: {
            size: String,
            use_internal: {
              type: Boolean,
              default: false
            }
        },
        model: {
            prop: 'size',
            event: 'change'
        },
        data() {
            return { changableMatrix: this.use_internal ? INTERNAL_CHANGABLE_MATRIX: CHANGABLE_MATRIX };
        }
    }
</script>
